import "./styles.scss";
import React from "react";

import PromotionInvestmentItem from "components/PromotionInvestmentItem";

const mainClass = "promotion__promotion-investments";

const PromotionInvestments = ({ data }) => {
  const investments_data = data?.map((item) => ({
    href: item?.uri,
    name: item?.title,
    logo: item?.investments?.investLogo?.sourceUrl,
    image: item?.investments?.mainImg?.sourceUrl,
    location: item?.investments?.shortLocation,
    regular_price: item?.investments?.promotionRegularPrice?.toFixed(0),
    currency: item?.investments?.currency,
    promotion_title: item?.investments?.promotionTitle,
    promotion_description: item?.investments?.promotionDescription,
    promotion_price: item?.investments?.promotionPrice?.toFixed(0),
    promotion_price_type: item?.investments?.promotionPriceType,
    promotion_lowest_price: item?.investments?.promotionLowestPrice?.toFixed(0),
    promotion_status: item?.investments?.promotionStatus?.[0],
    deadline: item?.investments?.deadline,
    labelState: item?.investments?.labelState,
    labelText: item?.investments?.labelText,
  }));
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__items`}>
          {investments_data?.map((props, index) => (
            <PromotionInvestmentItem {...props} key={index} />
          ))}
        </div>
      </div>
      <span id="inwestycje" className="anchor" />
    </div>
  );
};

export default PromotionInvestments;
