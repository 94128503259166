import "./styles.scss";

import React from "react";

import scrollToElement from "utils/scrollToElement";

import MoneyBoxIcon from "icons/money-box.svg";
import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "promotion__condo-market";

const CondoMarket = ({ heading, description }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h1>Promocje</h1>
          {(heading?.nomral || heading?.highlighted || heading?.small) && (
            <h2>
              {heading?.normal}
              {heading?.highlighted && (
                <>
                  {heading?.normal && <br />}
                  <span>atrakcyjnych warunków</span>
                </>
              )}
              {heading?.small && (
                <>
                  {(heading?.normal || heading?.highlighted) && <br />}
                  <small>na rynku condo</small>
                </>
              )}
            </h2>
          )}
        </div>
        <div className={`${mainClass}__content`}>
          <MoneyBoxIcon />
          {(description?.title || description?.text) && (
            <div
              dangerouslySetInnerHTML={{
                __html: `<p>${
                  description?.title
                    ? `<strong>${description.title}</strong> `
                    : ""
                }${description?.text ?? ""}</p>`,
              }}
            />
          )}
        </div>
        <div className={`${mainClass}__scroll`}>
          <button type="button" onClick={() => scrollToElement("#inwestycje")}>
            <span>
              <ArrowIcon />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CondoMarket;
